import { CenterAlignBox } from '@/@core/styles/common';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import logo from '../../../public/images/logos/logo-full.png';

const MainBox = {
  p: '30px',
  background: '#1f1933',
};

const ListsData = [
  {
    name: 'Products',
    items: [
      {
        label: 'Autonomous Finance Operations',
        link: 'products',
      },
    ],
  },
  {
    name: 'Solution',
    items: [
      {
        label: 'Autonomous Collections',
        link: 'autonomus-collections',
      },
      {
        label: 'Accounts Receivable Management',
        link: 'accounts-receivable-management',
      },
      // {
      //   label: 'Accounts Payable Management',
      //   link: 'accounts-payable-management',
      // },
      {
        label: 'Financial Reporting & Analysis',
        link: 'financial-reporting-and-analysis',
      },
      // {
      //   label: 'Compliance & Regulatory Reporting',
      //   link: 'compliance-and-regulatory-reporting',
      // },
      {
        label: 'AI Automation & Workflow Optimization',
        link: 'ai-automation-and-workflow-optimization',
      },
      {
        label: 'Advanced Risk Management',
        link: 'advanced-risk-management',
      },
      {
        label: 'Customer Engagement & Self-Service',
        link: 'customer-engagement-and-self-service',
      },
    ],
  },
  {
    name: 'Resources',
    items: [
      {
        label: 'Careers',
        link: '/careers',
      },
      {
        label: 'Blogs',
        link: 'https://blog.financeops.ai',
      },
      {
        label: 'E-books',
        link: '',
      },
      {
        label: 'Guides',
        link: '',
      },
      {
        label: 'Product Videos',
        link: '',
      },
    ],
  },
];

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  return (
    <Box
      className='common-footer-container'
      sx={{
        ...MainBox,
        '& a': {
          textDecoration: 'none !important',
        },
        ...(isMobile && {
          mt: 0,
        }),
      }}
    >
      <Box sx={{ ...CenterAlignBox }}>
        <Box sx={{ display: 'flex', gap: isMobile ? '30px' : '120px', flexWrap: 'wrap' }}>
          {ListsData?.map((list, listIndex) => {
            return (
              <Box key={listIndex}>
                <Typography sx={{ color: theme.palette.primary.main, fontWeight: 700, fontSize: '1.8rem' }}>
                  {list.name}
                </Typography>
                <Box>
                  {list.items?.map((item, itemIndex) => {
                    return item.link ? (
                      <Link key={itemIndex} href={`${item.link}`} passHref target='_blank'>
                        <Typography
                          sx={{
                            color: theme.palette.common.white,
                            fontWeight: 300,
                            py: '5px',
                            cursor: 'pointer',
                            fontSize: '1.4rem',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          key={itemIndex}
                        >
                          {item.label}
                        </Typography>
                      </Link>
                    ) : (
                      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            color: 'grey',
                            fontWeight: 300,
                            py: '5px',
                            cursor: 'pointer',
                          }}
                          key={itemIndex}
                        >
                          {item.label}
                        </Typography>
                        <Box
                          sx={{
                            color: '#fff',
                            fontSize: '0.8rem',
                            background: 'grey',
                            borderRadius: '12px',
                            p: '2px 4px',
                          }}
                        >
                          coming soon
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ pt: '86px' }}>
          <Typography sx={{ color: theme.palette.common.white, fontSize: '1.4rem', fontWeight: '300' }}>
            13001 Hymeadow Dr. Austin 78729 Texas
          </Typography>
          <Box sx={{ pt: '38px', display: 'flex', justifyContent: 'space-between' }}>
            <Image src={logo} alt={'FinanceOps'} width={120} height={30} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ color: '#fff', fontSize: '1.6rem', fontWeight: '300' }}>@ FinanceOps 2024</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', gap: '10px' }}>
            <Box
              onClick={() => router.push('/terms-of-service')}
              sx={{
                color: '#fff',
                fontWeight: 100,
                fontSize: '1.2rem',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
                flexBasis: 'max-content',
              }}
            >
              Terms of use
            </Box>
            <Box
              onClick={() => router.push('/privacy-policy')}
              sx={{
                color: '#fff',
                fontWeight: 100,
                fontSize: '1.2rem',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
                flexBasis: 'max-content',
              }}
            >
              Privacy Policy
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
