import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from 'react';
import createCustomTheme from '../@core/theme';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import '../globals.css';

function App({ Component, pageProps }: AppProps) {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const skin = 'default';
  const theme = createCustomTheme(mode, skin);
  const gaId = process.env.NEXT_PUBLIC_GA_ID || 'G-HHZSMNF17K';
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-MNN56PKQ';

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
    localStorage.removeItem('showContactForm');
  }, []);

  return (
    <>
      <Head>
        <title>FinanceOps - World's First Fully Autonomous Finance Operations Platform</title>
        <meta name='description' content="World's First Fully Autonomous Finance Operations Platform" />
        <link rel='shortcut' href='/images/favicon.png' />
        <link rel='icon' href='/images/favicon.png' />
        <link rel='preconnect' href='https://fonts.googleapis.com'></link>
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous'></link>
        <link
          href='https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
          rel='stylesheet'
        ></link>
        <script
          type='text/javascript'
          id='hs-script-loader'
          async
          defer
          src='//js-na1.hs-scripts.com/47053343.js'
        ></script>
        <script>{`function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script");
o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
o.onload=function(){window.trackingFunctions.onLoad({appId:"663275be03dcf6043ad52364"})},
document.head.appendChild(o)}initApollo();`}</script>
      </Head>
      <ThemeProvider theme={theme}>
        <GoogleAnalytics gaId={gaId} />
        <GoogleTagManager gtmId={gtmId}></GoogleTagManager>
        <CssBaseline />
        <Component {...pageProps} />
        <div id='calendly'></div>
      </ThemeProvider>
    </>
  );
}

export default App;
